import { Button } from "@mui/material";
import React from "react";

interface PremiumButtonProps {
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  height?: number;
  width?: number;
  fullWidth?: boolean;
}

export const premiumColor = "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)";

const PremiumButton: React.FC<PremiumButtonProps> = ({
  text,
  onClick,
  height = 18,
  width = "auto",
  fullWidth = false,
}) => (
  <Button
    onClick={onClick}
    variant="contained"
    fullWidth={fullWidth}
    style={{
      background: premiumColor,
      borderRadius: 3,
      border: 0,
      color: "white",
      height: height,
      width: fullWidth ? "100%" : width,
      padding: "0 10px",
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    }}>
    {text}
  </Button>
);

export default PremiumButton;
