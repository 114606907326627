import { Typography, TextField, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import callApi from "../utils/backend-caller";
import { useAuth } from "../utils/AuthContext";
import { useNotification } from "../utils/NotificationContext";
import Loading from "../components/loading/Loading";
import { Helmet } from "react-helmet";
import { getLogger } from "../utils/logger";
export default function ContactPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { setSeverity, setNotification } = useNotification();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { user, isLoading } = useAuth();

  useEffect(() => {
    setEmail(user?.email || "");
    setName(user?.displayName || "");
  }, [user]);

  const handleSubmit = async (event: React.FormEvent) => {
    setIsButtonDisabled(true);
    event.preventDefault();
    const formData = {
      name,
      email: user!.email,
      message,
    };
    try {
      const response = await callApi("/contact", "POST", user!, formData);

      if (!response.ok) {
        throw new Error("Failed to send message");
      }

      setMessage("");
      setSeverity("success");
      setNotification("Message sent successfully!");
    } catch (error) {
      getLogger("ContactPage").error("Error:", error);
      setSeverity("error");
      setNotification("Failed to send message, please try again later.");
    }
    setIsButtonDisabled(false);
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex justify-center">
        <Loading size="large" />
      </div>
    );
  }
  if (!user) {
    return (
      <div className="max-w-[600px] mx-auto px-4">
        <Typography variant="h4" component="h1" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please sign in to contact us.
        </Typography>
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <div className="max-w-[600px] mx-auto px-4 mt-8">
        <Typography variant="h4" component="h1" gutterBottom>
          Contact Us
        </Typography>
        <form
          onSubmit={async (e) => {
            await handleSubmit(e);
          }}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            required
          />
          <TextField
            label="Message"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={10}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isButtonDisabled}>
            Submit
          </Button>
        </form>
      </div>
    </>
  );
}
