import React from 'react';
import { Helmet } from 'react-helmet';

interface DefaultLayoutProps {
  children: React.ReactNode;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
  return (
    <>
      <Helmet defaultTitle="SmartForge" titleTemplate="%s | SmartForge">
        <meta name="description" content="SmartForge: AI-powered learning platform" />
      </Helmet>
      {children}
    </>
  );
};

export default DefaultLayout;
