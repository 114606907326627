import { CSSProperties, FC } from "react";

interface AwesomeTextAreaProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  className?: string;
  style?: CSSProperties;
}

const AwesomeTextArea: FC<AwesomeTextAreaProps> = ({ value, onChange, className = "", style = {} }) => {
  return (
    <textarea
      value={value}
      onChange={onChange}
      className={`w-full text-base rounded-lg p-3 bg-indigo-50 text-gray-800 ${className}`}
      style={{
        overflowY: "auto",
        resize: "none",
        wordWrap: "break-word",
        ...style
      }}
    />
  );
};

export default AwesomeTextArea;