import { User } from "firebase/auth";
import { getGenerationUsage, getUserSubscription } from "../utils/firestore-db";
import { SubscriptionData } from "@common/models";
import callApi from "../utils/backend-caller";
import { getLogger } from "../utils/logger";
function isSubscriptionValid(endDate: number) {
  const bufferInMs = 1000 * 60 * 60 * 24;
  return Date.now() < endDate + bufferInMs;
}

export async function hasValidSubscription(user: User | null) {
  if (user === null) {
    return false;
  }
  const subscriptionData: SubscriptionData | null = await getUserSubscription(user);
  if (!subscriptionData) {
    return false;
  }
  return isSubscriptionValid(subscriptionData.subscriptionValidUntilInMs);
}

export async function getGenerationCount(user: User) {
  const generationUsage = await getGenerationUsage(user);
  if (!generationUsage) {
    return 0;
  }
  const isSubscriptionValid = await hasValidSubscription(user);
  const usageType = isSubscriptionValid ? "subscription_usage" : "free_usage";
  return generationUsage[usageType] ?? 0;
}

export const getStripeSubscriptionDetails = async (user: User) => {
  try {
    const response = await callApi("/subscription", "GET", user);
    const body = await response.json();
    return body;
  } catch (error) {
    getLogger("subscription-service").error("Error getting subscription details:", error);
    throw error;
  }
};

const openInNewTab = (url: string) => {
  const newTab = window.open(url, "_blank");
  if (newTab) {
    newTab.focus();
  }
};

export const handleUpgrade = async (user: User | null) => {
  if (user === null) {
    throw new Error("User is not logged in.");
  }
  try {
    const response = await callApi("/subscribe", "POST", user);
    const body = await response.json();
    openInNewTab(body.url);
  } catch (error) {
    getLogger("subscription-service").error("Error upgrading subscription:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const handleManageBilling = async (user: User) => {
  try {
    const response = await callApi("/create-billing-portal-session", "POST", user);
    const body = await response.json();
    const url = body.billing_portal_url;
    if (!url) {
      throw new Error(
        "Something went wrong creating billing portal session, please try again later"
      );
    }
    openInNewTab(url);
  } catch (error) {
    getLogger("subscription-service").error("Error managing billing:", error);
    throw error; // Rethrow to handle it in the component
  }
};
