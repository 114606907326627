import { useState, useEffect } from "react";
import {
  getGenerationCount,
  hasValidSubscription,
} from "../services/subscription-service";
import { User } from "firebase/auth";

const useQuizUsage = (user: User | null) => {
  const [usageCount, setUsageCount] = useState<number>(0);
  const [usageLimit, setUsageLimit] = useState<number>(5);
  const [isSubscriber, setIsSubscriber] = useState<boolean>(false);

  useEffect(() => {
    async function fetchUsageData() {
      if (user !== null) {
        const count = await getGenerationCount(user);
        setUsageCount(count);
        const subscribed = await hasValidSubscription(user);
        const limit = subscribed ? 20 : 5;
        setIsSubscriber(subscribed);
        setUsageLimit(limit);
      }
    }
    fetchUsageData();
  }, [user]);

  return { usageCount, usageLimit, isSubscriber, setUsageCount };
};

export default useQuizUsage;
