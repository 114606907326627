import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../utils/NotificationContext";
import { Helmet } from "react-helmet";
function PaymentSuccessPage() {
  const { setNotification } = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    setNotification("Subscription success!");
  }, [setNotification]);

  const handleStart = () => {
    navigate("/");
  };

  return (
    <>
      <Helmet>
        <title>Payment Successful</title>
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh">
        <Typography variant="h2" align="center" gutterBottom>
          Payment Successful!
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" gutterBottom>
          Thank you for your purchase. You can now start using our service.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleStart}>
          Start
        </Button>
      </Box>
    </>
  );
}

export default PaymentSuccessPage;
