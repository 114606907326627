import QuizIntake from "../components/QuizIntake";
import Loading from "../components/loading/Loading";
import { useAuth } from "../utils/AuthContext";
import LandingPage from "./LandingPage";

const MainPage = () => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="min-h-screen flex justify-center">
        <Loading size="large" />
      </div>
    );
  }
  return user ? <QuizIntake /> : <LandingPage />;
};

export default MainPage;
