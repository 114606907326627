import { User } from "firebase/auth";
import { createAuthHeaders } from "../utils/auth-utils";
import config from "./config-loader";
import { getLogger } from "./logger";
export default async function callApi(
  endpoint: string,
  method: "GET" | "POST",
  user?: User,
  payload?: any
): Promise<any> {
  try {
    const headers: HeadersInit = { "Content-Type": "application/json" };
    if (user) {
      const authHeader = await createAuthHeaders(user);
      Object.assign(headers, authHeader);
    }

    const options: RequestInit = {
      method,
      headers,
    };

    if (method === "POST" && payload) {
      options.body = JSON.stringify(payload);
    }

    const response = await fetch(`${config.backendUrl}${endpoint}`, options);
    return response;
  } catch (error) {
    getLogger("backend-caller").error("Error calling API:", error);
    const originalErrMsg = (error as Error).message;
    const errMsg = {
      error: "Something went wrong. Please try again later.",
      cause: originalErrMsg,
    };
    throw new Error(JSON.stringify(errMsg));
  }
}
