import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Quiz } from "@common/models";
import { upsertQuizWithCache } from "@/utils/quiz-dao";
import { useAuth } from "@/utils/AuthContext";
import Loading from "../components/loading/Loading";
import { useNavigate } from "react-router-dom";
import { escapeLineBreaks } from "../utils/string-utils";
import { Helmet } from "react-helmet";
export const CreateQuizPage: React.FC = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();

  const createNewQuiz = (question: string, answer: string): Quiz => {
    return {
      id: uuidv4(),
      question: escapeLineBreaks(question),
      answer: escapeLineBreaks(answer),
      creationTime: new Date().toISOString(),
      lastReviewedTime: new Date(0).toISOString(),
      nextReviewTime: new Date().toISOString(),
      currentReviewCount: 0,
      totalReviewCount: 0,
    };
  };

  async function onSaveToCollection(newQuiz: Quiz) {
    await upsertQuizWithCache(user!, newQuiz);
  }

  const handleSaveToCollection = async () => {
    const newQuiz = createNewQuiz(question, answer);
    await onSaveToCollection(newQuiz);
    setIsSaved(true);
    setTimeout(() => {
      setQuestion("");
      setAnswer("");
      setIsSaved(false);
    }, 1000);
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex justify-center">
        <Loading size="large" />
      </div>
    );
  }
  if (!user) {
    navigate("/");
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Create Quiz</title>
      </Helmet>
      <div className="bg-gradient-to-b from-indigo-50 to-white py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
          <div className="p-8">
            <h2 className="text-3xl font-bold text-center text-indigo-900 mb-6">
              Create a New Quiz
            </h2>
            <div className="space-y-6">
              <div>
                <label
                  className="block text-sm font-medium text-gray-700 mb-2"
                  htmlFor="question">
                  Question
                </label>
                <textarea
                  id="question"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                  rows={3}
                  placeholder="Enter your question here"
                />
              </div>
              <div>
                <label
                  className="block text-sm font-medium text-gray-700 mb-2"
                  htmlFor="answer">
                  Answer
                </label>
                <textarea
                  id="answer"
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                  rows={5}
                  placeholder="Enter your answer here"
                />
              </div>
              <div className="flex justify-end">
                <button
                  onClick={handleSaveToCollection}
                  className={`px-6 py-3 rounded-full text-white font-semibold transition-colors ${
                    isSaved
                      ? "bg-green-500 cursor-not-allowed"
                      : "bg-indigo-600 hover:bg-indigo-700"
                  }`}
                  disabled={isSaved || !question.trim() || !answer.trim()}>
                  {isSaved ? "Saved!" : "Save to Collection"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
