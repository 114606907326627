import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const FeatureSection: React.FC<{ title: string; children: React.ReactNode }> = ({
  title,
  children,
}) => (
  <section className="mb-8">
    <h2 className="text-3xl font-bold text-indigo-800 mb-4">{title}</h2>
    {children}
  </section>
);

const FeatureList: React.FC<{ items: string[] }> = ({ items }) => (
  <ul className="space-y-2">
    {items.map((item, index) => (
      <li key={index} className="flex items-start">
        <svg
          className="w-6 h-6 text-indigo-500 mr-2 flex-shrink-0"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"></path>
        </svg>
        <span className="text-gray-700">{item}</span>
      </li>
    ))}
  </ul>
);

export default function FeaturesPage() {
  const navigate = useNavigate();

  const keyFeatures = [
    "AI-Powered quiz Generation: Automatically transform any text into interactive flashcard-style quizzes.",
    "Spaced Repetition System: Improve long-term memory retention with our advanced review system.",
    "User-Friendly Interface: Access your quizzes and progress anytime, anywhere, on any device.",
  ];

  const howItWorks = [
    "Input Your Text: Start by pasting any text into SmartForge.",
    "Generate Your Quizzes: With one click, our AI analyzes your text and creates tailored flashcard-style quizzes.",
    "Learn and Review: Engage with the quizzes to learn and use our spaced-repetition system to ensure long-term memory retention.",
  ];

  return (
    <>
      <Helmet>
        <title>Features</title>
        <meta
          name="description"
          content="Discover the powerful features of SmartForge, including AI-powered quiz generation and spaced repetition learning."
        />
      </Helmet>
      <div className="bg-gradient-to-b from-indigo-50 to-white min-h-screen py-12">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold text-indigo-900 mb-6 text-center">
            SmartForge: Revolutionize Your Learning with AI
          </h1>

          <p className="text-xl text-indigo-700 mb-8 text-center">
            Discover the power of personalized learning with SmartForge, the innovative
            platform designed to transform the way you learn, review, and retain
            information.
          </p>

          <FeatureSection title="How SmartForge Works">
            <ol className="list-decimal list-inside space-y-2 text-gray-700">
              {howItWorks.map((step, index) => (
                <li key={index} className="mb-2">
                  <span className="font-semibold">{step.split(":")[0]}:</span>
                  {step.split(":")[1]}
                </li>
              ))}
            </ol>
          </FeatureSection>

          <FeatureSection title="Why Choose SmartForge?">
            <p className="text-gray-700">
              With SmartForge, learning is no longer a one-size-fits-all journey. Our
              platform offers personalized learning experiences, making studying more
              effective and engaging. From saving time on quiz creation to enhancing
              knowledge retention with spaced repetition, SmartForge is your partner in
              achieving your learning goals.
            </p>
          </FeatureSection>

          <FeatureSection title="Key Features">
            <FeatureList items={keyFeatures} />
          </FeatureSection>

          <div className="mt-12 text-center">
            <button
              onClick={() => navigate("/signin")}
              className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105">
              Start Your Learning Journey Now
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
