import React from "react";
import { useNavigate, Link } from "react-router-dom";
import PricingPlans from "../components/PricingPlan";

const Feature: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => (
  <div className="mb-6">
    <h3 className="text-xl font-semibold mb-2 text-indigo-600">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  const handleSignUp = () => {
    navigate("/signin");
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center mb-16">
          <h1 className="text-5xl sm:text-6xl font-bold text-gray-900 mb-4">
            SmartForge: AI-Powered Learning
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Revolutionize Your Learning Efficiency with AI-Enhanced Flashcards and
            Intelligent Spaced Repetition
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-xl p-8 mb-16">
          <h2 className="text-3xl font-semibold text-center text-gray-800 mb-8">
            Unlock the Power of AI-Driven Learning
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            <Feature
              title="Instant Flashcard Generation"
              description="Transform any text into comprehensive flashcards in seconds, significantly reducing preparation time"
            />
            {/* todo this feature not implemented yet  */}
            {/* <Feature
              title="AI-Powered Deck Expansion"
              description="Automatically create new cards based on your existing ones, ensuring thorough topic coverage"
            /> */}
            <Feature
              title="Customizable Spaced Repetition"
              description="User-defined review schedules to optimize your learning rhythm and improve long-term retention"
            />
            <Feature
              title="Flexible Content Creation"
              description="Seamlessly blend AI-generated and manually crafted flashcards for a tailored learning experience"
            />
            <Feature
              title="Cross-Platform"
              description="Access your decks anytime, anywhere - perfect for busy professionals and students on-the-go"
            />
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-xl p-8 mb-16">
          <h2 className="text-3xl font-semibold text-center text-gray-800 mb-8">
            What SmartForge Does
          </h2>
          <p className="text-lg text-gray-600 mb-6">
            SmartForge is an innovative AI-powered learning platform that revolutionizes
            how you create, study, and retain knowledge. You can effortlessly generate
            flashcards with our advanced AI algorithms based on your learning materials,
            or simply manually craft your own flashcards. Boost your learning with our
            cutting-edge spaced-repetition system, designed to maximize knowledge
            retention and efficiency.
          </p>
          <p className="text-lg text-gray-600 mb-8">
            Whether you're a student preparing for exams, a professional staying current
            in your field, or a lifelong learner exploring new subjects, SmartForge
            supercharges your learning journey.
          </p>
          <div className="text-center">
            <Link
              to="/features"
              className="inline-block bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105">
              Explore All Features
            </Link>
          </div>
        </div>

        <div className="text-center mb-16">
          <p className="text-xl text-gray-600 mb-8">
            Designed for ambitious learners, from aspiring students to future industry
            leaders
          </p>
          <button
            onClick={handleSignUp}
            className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105">
            Start Learning Smarter - Sign Up Now
          </button>
          <p className="mt-4 text-sm text-gray-500">(no credit card required)</p>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
