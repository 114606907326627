import { getQuizzesWithCache, updateQuiz } from "@/utils/quiz-dao";
import { RegularReviewScheduler } from "@/utils/review-scheduler";
import { Quiz } from "@common/models";
import { User } from "firebase/auth";
import React, { useEffect } from "react";
import FlashCard from "../components/FlashCard";
import { useAuth } from "../utils/AuthContext";
import { Helmet } from "react-helmet";
type Props = {};

export interface ReviewResult {
  result: "again" | "easy" | "good" | "hard";
}

export default function ReviewSessionPage({}: Props) {
  const { user, isLoading: isAuthLoading } = useAuth();
  const [quizzes, setQuizzes] = React.useState<Quiz[]>([]);
  const [currentQuizIndex, setCurrentQuizIndex] = React.useState(0);
  const [reviewFinished, setReviewFinished] = React.useState(false);

  async function getQuizForReview(user: User): Promise<Quiz[]> {
    const quizzes = await getQuizzesWithCache(user);
    const readyForReview = quizzes.filter((q) => {
      return new Date(q.nextReviewTime!).getTime() < new Date().getTime();
    });
    return readyForReview;
  }

  function updateQuizUponReview(quiz: Quiz, reviewResult: ReviewResult): Quiz {
    const reviewScheduler = new RegularReviewScheduler();
    const updatedQuiz = { ...quiz };
    updatedQuiz.currentReviewCount = (quiz.currentReviewCount ?? 0) + 1;
    updatedQuiz.totalReviewCount = (quiz.totalReviewCount ?? 0) + 1;
    updatedQuiz.lastReviewedTime = new Date().toISOString();
    updatedQuiz.nextReviewTime = reviewScheduler
      .getNextReviewTime(quiz, reviewResult)
      .toISOString();

    return updatedQuiz;
  }

  async function onReviewed(quiz: Quiz, reviewResult: ReviewResult) {
    const nextQuizIndex = currentQuizIndex + 1;
    const updatedQuiz = updateQuizUponReview(quiz, reviewResult);
    await updateQuiz(user!, updatedQuiz);

    if (reviewResult.result === "again") {
      setQuizzes((prevQuizzes) => {
        const updatedQuizzes = [...prevQuizzes, quiz];
        if (nextQuizIndex < updatedQuizzes.length) {
          setCurrentQuizIndex(nextQuizIndex);
        } else {
          setReviewFinished(true);
        }
        return updatedQuizzes;
      });
    } else {
      if (nextQuizIndex < quizzes.length) {
        setCurrentQuizIndex(nextQuizIndex);
      } else {
        setReviewFinished(true);
      }
    }
  }

  useEffect(() => {
    const getQuizzes = async () => {
      if (!user) {
        return;
      }
      const quizzesForReview = await getQuizForReview(user!);
      setQuizzes(quizzesForReview);
    };
    getQuizzes();
  }, [user]);

  if (reviewFinished) {
    return (
      <>
        <Helmet>
          <title>Review Session</title>
        </Helmet>
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-indigo-50 to-purple-50 p-4">
          <div className="bg-white rounded-lg shadow-lg p-8 text-center">
            <h2 className="text-3xl font-bold text-indigo-700 mb-4">Review Complete!</h2>
            <p className="text-xl text-gray-700">
              You finished reviewing all that need review. Keep up the good work!
            </p>
          </div>
        </div>
      </>
    );
  }

  const nothingToReviewDiv = (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-indigo-50 to-purple-50 p-4">
      <div className="bg-white rounded-lg shadow-lg p-8 text-center">
        <h2 className="text-3xl font-bold text-indigo-700 mb-4">No Reviews Due</h2>
        <p className="text-xl text-gray-700">
          Nothing to review yet, please come back later!
        </p>
      </div>
    </div>
  );

  const reviewSessionDiv = (
    <div className=" bg-gradient-to-b from-indigo-50 to-white py-8 px-4 sm:px-6 lg:px-8 ">
      <div className="max-w-2xl mx-auto">
        <div className="bg-white shadow-md rounded-lg p-4 mb-6 flex items-center justify-between">
          <h1 className="text-2xl font-bold text-indigo-700">Review Session</h1>
          <p className="text-lg text-gray-700">
            {(quizzes?.length ?? 0) - currentQuizIndex} cards left
          </p>
        </div>
        {quizzes && quizzes.length != 0 && (
          <FlashCard quiz={quizzes[currentQuizIndex]} onReviewed={onReviewed} />
        )}
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Review Session</title>
      </Helmet>
      {quizzes && quizzes.length === 0 ? nothingToReviewDiv : reviewSessionDiv}
    </>
  );
}
