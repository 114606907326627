import React from "react";
import PricingPlans from "../components/PricingPlan";
import { Helmet } from "react-helmet";

const PricingPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Pricing</title>
      </Helmet>
      <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h1 className="text-4xl font-bold text-center text-indigo-900 mb-8">
          Choose Your Plan
        </h1>
        <p className="text-xl text-center text-indigo-700 mb-12">
          Select the perfect plan to supercharge your learning journey
        </p>
        <PricingPlans />
      </div>
    </>
  );
};

export default PricingPage;
