import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAPuwXg4Go-XNsu0Q_oB8XZfp0uoN8PFGg",
  authDomain: "quiz-creator-cobrakai.firebaseapp.com",
  projectId: "quiz-creator-cobrakai",
  storageBucket: "quiz-creator-cobrakai.appspot.com",
  messagingSenderId: "880838707541",
  appId: "1:880838707541:web:013201618eb3711462c7dc",
  measurementId: "G-9Q1VYSV7DZ",
};

const prodfirebaseConfig = {
  projectId: "smartforge-prod",
  appId: "1:213425564560:web:b90543ad8ab10a187d6115",
  storageBucket: "smartforge-prod.appspot.com",
  apiKey: "AIzaSyD0in4mJU5wuSsVLjNgwyOwmZd09adyIy0",
  authDomain: "smartforge-prod.firebaseapp.com",
  messagingSenderId: "213425564560",
  measurementId: "G-NF4P46YHG9",
};

const app = initializeApp(
  process.env.REACT_APP_ENV !== "prod" ? firebaseConfig : prodfirebaseConfig
);

export default app;
