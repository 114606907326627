import { User } from "firebase/auth";
import callApi from "../utils/backend-caller";
import { UserDoc } from "@common/models";
import { getLogger } from "@/utils/logger";

async function createNewUserDoc(user: User): Promise<UserDoc> {
  try {
    const response = await callApi("/user", "POST", user);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const userDoc = (await response.json()) as UserDoc;
    return userDoc;
  } catch (error) {
    getLogger("user-service").error("Error creating new user doc:", error);
    throw error;
  }
}

async function markVerifyEmailSent(user: User): Promise<void> {
  try {
    const response = await callApi("/user/mark-email-sent", "POST", user);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    getLogger("user-service").error("Error marking email as sent:", error);
  }
}

async function isVerifyEmailSent(user: User): Promise<boolean> {
  try {
    const response = await callApi("/user", "GET", user);
    if (response.status === 404) {
      return false;
    }
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const userData = (await response.json()) as UserDoc;
    const result = userData.is_verify_email_sent;
    return result;
  } catch (error) {
    getLogger("user-service").error("Error fetching user data:", error);
    return false;
  }
}

export { markVerifyEmailSent, isVerifyEmailSent, createNewUserDoc };
