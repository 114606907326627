import { getAuth, signOut } from "firebase/auth";
import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../utils/AuthContext";
import app from "../utils/firebase";
import AwesomeAvatar from "./AwesomeAvatar";
import { getLogger } from "../utils/logger";
const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const auth = getAuth(app);

  const menuItems: MenuItem[] = [
    { name: "Features", path: "/features" },
    { name: "Pricing", path: "/pricing" },
    { name: "Sign Up / Sign In", path: "/signin" },
  ];

  const menuItemsLoggedIn: MenuItem[] = [
    { name: "Generate", path: "/" },
    { name: "Create", path: "/create" },
    { name: "Review", path: "/review" },
    { name: "Collections", path: "/collections" },
    { name: "Profile", path: "/profile", display: "block sm:hidden" },
    { name: "Contact", path: "/contact", display: "block sm:hidden" },
    {
      name: "Sign Out",
      path: "/signin",
      display: "block sm:hidden",
      fn: async () => await handleSignOut(),
    },
  ];

  interface MenuItem {
    name: string;
    path: string;
    display?: string;
    fn?: () => Promise<void>;
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/signin");
    } catch (error) {
      getLogger("NavBar").error("Error signing out:", error);
    }
  };

  return (
    <div className="flex flex-col fixed top-0 left-0 z-50 w-full bg-white shadow-md">
      <div className="flex justify-between">
        <nav className="flex flex-grow items-center justify-between flex-wrap bg-white p-4">
          <div className="flex items-center flex-shrink-0 text-indigo-700 mr-6">
            <Link to="/" className="font-semibold text-2xl">
              <img src="/logo-orange.png" alt="Logo" className="mx-auto mt-1 max-w-40" />
            </Link>
          </div>
          <div className="block sm:hidden">
            <button
              onClick={toggleMenu}
              className="flex items-center px-3 py-2 border rounded text-indigo-600 border-indigo-600 hover:text-indigo-900 hover:border-indigo-900">
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div
            className={`${
              isOpen ? "block" : "hidden"
            } w-full flex-grow sm:flex sm:items-center sm:w-auto`}>
            <div className="text-lg sm:flex-grow backdrop-blur-sm rounded">
              {(user ? menuItemsLoggedIn : menuItems).map((item) => (
                <NavLink
                  key={item.name}
                  to={item.path}
                  onClick={() => {
                    setIsOpen(false);
                    if (item.fn) {
                      item.fn();
                    }
                  }}
                  className={({ isActive }) =>
                    `${
                      item.display ?? "block"
                    } font-medium tracking-wide mt-4 sm:inline-block sm:mt-0 text-indigo-600 hover:text-indigo-900 mr-4 text-right ${
                      isActive ? "underline" : ""
                    }`
                  }>
                  {item.name}
                </NavLink>
              ))}
            </div>
          </div>
        </nav>
        <div className="hidden sm:block mx-2 ">
          {user && <AwesomeAvatar user={user} />}
        </div>
      </div>
      <div className="border-b border-indigo-200 w-full"></div>
    </div>
  );
};

export default Navbar;
