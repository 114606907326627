import React from "react";
import "./Loading.css"; // Import the CSS file

interface LoadingProps {
  size?: "small" | "medium" | "large";
}

const Loading: React.FC<LoadingProps> = ({ size = "medium" }) => {
  const sizeClasses = {
    small: "w-6 h-6",
    medium: "w-10 h-10",
    large: "w-16 h-16",
  };

  return (
    <div className="flex justify-center items-center">
      <div
        className={`animate-spin rounded-full border-t-2 border-b-2 border-indigo-500 ${sizeClasses[size]}`}></div>
    </div>
  );
};

export default Loading;
