import { Quiz } from "@common/models";
import callApi from "../utils/backend-caller";
import { User } from "firebase/auth";

export const quizService = {
  async generateQuizzes(user: User, text: string): Promise<{ quizzes: Quiz[] }> {
    const res = await callApi("/quizzes/generate", "POST", user, { text });
    if (!res.ok) {
      const errorMsg = await res.json();
      throw new Error(JSON.stringify(errorMsg, null, 2));
    }
    const data = await res.json();
    return data;
  },
};
