import React from "react";
import { useNavigate } from "react-router-dom";

interface PlanProps {
  title: string;
  price: string;
  features: string[];
  isPro?: boolean;
}

const Plan: React.FC<PlanProps> = ({ title, price, features, isPro = false }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`p-6 rounded-lg shadow-lg flex flex-col justify-between ${
        isPro ? "bg-indigo-100 border-2 border-indigo-500" : "bg-white"
      }`}>
      <div>
        <h2
          className={`text-2xl font-bold mb-2 ${
            isPro ? "text-indigo-700" : "text-indigo-600"
          }`}>
          {title}
        </h2>
        <h3 className="text-3xl font-bold mb-6 text-gray-800">{price}</h3>
        <ul className="space-y-3">
          {features.map((feature, index) => (
            <li key={index} className="flex items-start">
              <svg
                className="w-6 h-6 text-indigo-500 mr-2 flex-shrink-0"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"></path>
              </svg>
              <span className="text-gray-600">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
      <button
        onClick={() => navigate("/signin")}
        className={`mt-8 w-full py-3 px-4 rounded-full font-semibold text-white transition-colors duration-300 ${
          isPro
            ? "bg-indigo-600 hover:bg-indigo-700"
            : "bg-indigo-500 hover:bg-indigo-600"
        }`}>
        {isPro ? "Subscribe Now" : "Get Started"}
      </button>
    </div>
  );
};

const PricingPlans: React.FC = () => {
  const basicFeatures = [
    "Use AI to generate flashcards from 5 articles per month",
    "Create unlimited flashcards manually",
    "Optimize learning with our spaced-repetition system",
    "No credit card required to sign up",
    "Free forever",
  ];
  const proFeatures = [
    "Everything included in Basic",
    "Use AI to generate flashcards from 20 articles per month",
    "Enjoy more upcoming features, including customizable review schedules",
    "Priority support",
    "Cancel anytime",
  ];

  return (
    <div className="grid md:grid-cols-2 gap-8">
      <Plan title="Basic" price="Free forever" features={basicFeatures} />
      <Plan title="Pro" price="$6/month" features={proFeatures} isPro={true} />
    </div>
  );
};

export default PricingPlans;
