import React from "react";
import AwesomeTextArea from "./AwesomeTextArea";
import PremiumUpgrade from "./PremiumUpgradeBox";
import QuizResultDisplay from "./QuizResultDisplay";
import Loading from "./loading/Loading";
import useQuizIntake from "../hooks/use-quiz-intake-hook";
import { INPUT_CHAR_LIMIT } from "@/constants";
import { Helmet } from "react-helmet";
const QuizIntake: React.FC = () => {
  const {
    text,
    setText,
    response,
    usageCount,
    usageLimit,
    isSubscriber,
    isBackendLoading,
    textareaHeight,
    quizDisplayRef,
    handleSubmit,
    user,
    error,
    clearError,
    openPremiumModal,
    setOpenPremiumModal,
  } = useQuizIntake();

  return (
    <>
      <Helmet>
        <title>Generate Quiz</title>
      </Helmet>
      <div className="flex flex-col bg-gradient-to-b from-indigo-50 to-white">
        <div className="flex-grow overflow-auto">
          <div className="max-w-4xl mx-auto px-4 py-8 flex flex-col">
            <div className="bg-white shadow-md rounded-lg p-6 mb-8">
              <h2 className="text-3xl font-bold text-indigo-900 mb-2 text-center">
                Create Your Quiz
              </h2>
              <p className="text-gray-600 text-center mb-6">
                Paste your text below and we'll generate quiz questions for you.
              </p>

              <div className="mb-4 flex flex-col">
                <AwesomeTextArea
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  className="shadow-inner flex-grow"
                  style={{ height: textareaHeight }}
                />
                <div className="flex justify-end mt-2">
                  <span
                    className={`text-sm ${
                      text.length > INPUT_CHAR_LIMIT ? "text-red-500" : "text-gray-500"
                    }`}>
                    {text.length}/{INPUT_CHAR_LIMIT}
                  </span>
                </div>
              </div>

              <div className="flex flex-col items-center mt-4">
                <button
                  className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-6 rounded-full w-full max-w-xs transition duration-300 ease-in-out transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={handleSubmit}
                  disabled={text.length === 0 || isBackendLoading}>
                  {isBackendLoading ? "Generating..." : "Create Quizzes"}
                </button>

                <div className="mt-6 w-full max-w-xs">
                  <div className="flex justify-between text-sm text-gray-600 mb-1">
                    <span>Usage</span>
                    <span>
                      {usageCount}/{usageLimit} quizzes
                    </span>
                  </div>
                  <div className="bg-gray-200 rounded-full h-2 overflow-hidden">
                    <div
                      className="bg-indigo-600 h-2 rounded-full transition-all duration-300 ease-in-out"
                      style={{ width: `${(usageCount / usageLimit) * 100}%` }}></div>
                  </div>
                  {!isSubscriber && usageCount > 0 && (
                    <p className="text-xs text-gray-500 mt-2">
                      Running low?{" "}
                      <button
                        onClick={() => setOpenPremiumModal(true)}
                        className="text-indigo-600 hover:underline">
                        Upgrade to Pro
                      </button>
                    </p>
                  )}
                </div>
              </div>
            </div>

            {user && response?.quizzes && (
              <div
                className="bg-white shadow-md rounded-lg p-6 mb-8"
                ref={quizDisplayRef}>
                <h3 className="text-2xl font-bold text-indigo-900 mb-4">
                  Your Generated Quizzes
                </h3>
                <QuizResultDisplay quizzes={response.quizzes} />
              </div>
            )}
          </div>
        </div>

        {isBackendLoading && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-lg shadow-xl text-center max-w-sm w-full">
              <Loading size="medium" />
              <p className="mt-4 text-gray-700 font-semibold">
                Generating your quizzes...
              </p>
              <p className="mt-2 text-gray-500 text-sm">This may take a few moments</p>
            </div>
          </div>
        )}

        {error && (
          <div className="fixed inset-x-0 top-4 flex items-center justify-center z-50">
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg shadow-md max-w-md w-full relative"
              role="alert">
              <strong className="font-bold">Error: </strong>
              <span className="block sm:inline mr-8">{error}</span>
              <button
                className="absolute top-0 right-0 mt-2 mr-2 text-red-500 hover:text-red-700"
                onClick={clearError}>
                <svg
                  className="h-6 w-6 fill-current"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20">
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </button>
            </div>
          </div>
        )}

        {openPremiumModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-2xl shadow-2xl max-w-lg w-full overflow-hidden">
              <PremiumUpgrade
                user={user}
                displayText="You have reached the generation limit. Please subscribe to our Pro Plan to generate more quizzes."
                onClose={() => setOpenPremiumModal(false)}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default QuizIntake;
