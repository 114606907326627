import { getLogger } from "./utils/logger";
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  User,
  UserCredential,
  getAuth,
  sendEmailVerification,
} from "firebase/auth";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createNewUserDoc,
  isVerifyEmailSent,
  markVerifyEmailSent,
} from "./services/user-service";
import { useAuth } from "./utils/AuthContext";
import { useNotification } from "./utils/NotificationContext";
import app from "./utils/firebase";
import { getUserDoc } from "./utils/firestore-db";
import { Helmet } from "react-helmet";
const logger = getLogger("auth");

function sendVerifyEmail(user: User) {
  try {
    sendEmailVerification(user);
    logger.info("Verification email sent.");
    markVerifyEmailSent(user);
  } catch (error) {
    logger.error("Error sending verification email:", error);
  }
}

const shouldSendEmailVerification = async (user: User) => {
  logger.info(`User ${user.email} verify status: ${user.emailVerified}`);
  return !user.emailVerified && !(await isVerifyEmailSent(user));
};

function Auth() {
  const auth = getAuth(app);
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const { setNotification } = useNotification();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (user) {
      navigate("/profile");
    }
  }, [user, navigate]);

  const sendVerifyEmailIfNeeded = (user: User) => {
    shouldSendEmailVerification(user).then((shouldSend) => {
      if (shouldSend) {
        sendVerifyEmail(user);
        setNotification("We sent you a verification email. Please check your inbox.");
      }
    });
  };

  const createNewUserDocIfNeeded = (user: User) => {
    getUserDoc(user.uid)
      .then((userDoc) => {
        if (!userDoc) {
          createNewUserDoc(user)
            .then(() => sendVerifyEmailIfNeeded(user))
            .catch((error) => logger.error("Error creating new user document: ", error));
        }
      })
      .catch((error) => logger.error("Error getting user document: ", error));
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, [auth, setUser]);

  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [GoogleAuthProvider.PROVIDER_ID, EmailAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: (authResult: UserCredential) => {
        logger.info(`user signed in successfully: user email: ${authResult.user.email}`);
        setUser(authResult.user);
        createNewUserDocIfNeeded(authResult.user);
        return false; // Prevents redirect by FirebaseUI
      },
    },
  };

  useEffect(() => {
    if (!user && document.getElementById("firebaseui-auth-container")) {
      const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
      ui.start("#firebaseui-auth-container", uiConfig);
    }
  }, [auth, uiConfig, user]);

  if (user) {
    return null; // or a loading spinner if you prefer
  }

  return (
    <>
      <Helmet>
        <title>Sign in</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-indigo-900">
              Sign in to your account
            </h2>
          </div>
          <div id="firebaseui-auth-container" className="mt-8" />
        </div>
      </div>
    </>
  );
}

export default Auth;
