import { Quiz } from "@common/models";
import { Divider, List } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { QuizEntry } from "./QuizEntry";
import { upsertQuizWithCache } from "@/utils/quiz-dao";
import { useAuth } from "@/utils/AuthContext";

interface QuizResultDisplayProps {
  quizzes: Quiz[];
}

const QuizResultDisplay: React.FC<QuizResultDisplayProps> = ({ quizzes }) => {
  const { user } = useAuth();

  return (
    <Card sx={{ maxWidth: "100%" }}>
      <CardContent>
        <List>
          {quizzes &&
            quizzes.map((quiz, index) => (
              <div key={index}>
                <QuizEntry
                  quiz={quiz}
                  index={index}
                  isInCollections={false}
                  onSaveToCollection={async (quizToSave) =>
                    await upsertQuizWithCache(user!, quizToSave)
                  }
                />
                {index !== quizzes.length - 1 && <Divider />}
              </div>
            ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default QuizResultDisplay;
