import { useEffect, useRef, useState } from "react";
import { quizService } from "../services/quiz-service";
import { useAuth } from "../utils/AuthContext";
import { handleQuizGenerationError, validateInput } from "../utils/quiz-intake-utils";
import useQuizUsage from "./use-quiz-usage-hook";
import useErrorHandler from "./use-error-handler-hook";
import { useAnalytics } from "../hooks/use-analytics";

const useQuizIntake = () => {
  const [text, setText] = useState("");
  const [response, setResponse] = useState<any>();
  const [isBackendLoading, setIsBackendLoading] = useState<boolean>(false);
  const [textareaHeight, setTextareaHeight] = useState("40vh");
  const [openPremiumModal, setOpenPremiumModal] = useState(false);

  const quizDisplayRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const { usageCount, usageLimit, isSubscriber, setUsageCount } = useQuizUsage(user);
  const { error, showError, clearError } = useErrorHandler();
  const { logCustomEvent } = useAnalytics();

  useEffect(() => {
    const updateTextareaHeight = () => {
      const vh = window.innerHeight;
      const availableHeight = vh - 450; // Approximate space for other elements
      const newHeight = Math.max(200, availableHeight); // Minimum height of 200px
      setTextareaHeight(`${newHeight}px`);
    };

    updateTextareaHeight();
    window.addEventListener("resize", updateTextareaHeight);

    return () => window.removeEventListener("resize", updateTextareaHeight);
  }, []);

  useEffect(() => {
    quizDisplayRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [response]);

  const handleSubmit = async () => {
    if (user && !user.emailVerified) {
      showError(
        "Please verify your email before generating quizzes. You can request a new verification email from your profile page."
      );
      logCustomEvent("quiz_generation_attempt", {
        status: "failed",
        reason: "email_not_verified",
      });
      return;
    }

    const validateResult = validateInput(text);
    if (!validateResult.valid) {
      showError(validateResult.message);
      logCustomEvent("quiz_generation_attempt", {
        status: "failed",
        reason: "invalid_input",
      });
      return;
    }

    if (usageCount >= usageLimit && !isSubscriber) {
      setOpenPremiumModal(true);
      logCustomEvent("quiz_generation_attempt", {
        status: "failed",
        reason: "usage_limit_reached",
      });
      return;
    }

    try {
      setIsBackendLoading(true);
      logCustomEvent("quiz_generation_attempt", {
        status: "started",
        text_length: text.length,
      });
      const returnedQuizzes = await quizService.generateQuizzes(user!, text);
      setText("");
      setResponse(returnedQuizzes);
      setUsageCount((prevCount) => prevCount + 1);
      logCustomEvent("quiz_generation_attempt", {
        status: "success",
        quizzes_count: returnedQuizzes.quizzes.length,
        is_subscriber: isSubscriber,
      });
    } catch (error) {
      showError(handleQuizGenerationError(error as Error));
      logCustomEvent("quiz_generation_attempt", {
        status: "failed",
        reason: "api_error",
        error_message: (error as Error).message,
      });
    } finally {
      setIsBackendLoading(false);
    }
  };

  return {
    text,
    setText,
    response,
    isBackendLoading,
    usageCount,
    usageLimit,
    isSubscriber,
    textareaHeight,
    quizDisplayRef,
    handleSubmit,
    user,
    error,
    clearError,
    openPremiumModal,
    setOpenPremiumModal,
  };
};

export default useQuizIntake;
