import React, { useEffect, useState } from "react";
import { useAuth } from "../utils/AuthContext";
import callApi from "../utils/backend-caller";

interface RefillCreditsProps {}
const RefillCredits: React.FC<RefillCreditsProps> = ({}) => {
  const [credits, setCredits] = useState(0);
  const [message, setMessage] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  const handleBuy = async () => {
    try {
      const response = await callApi("/payment/refill", "POST", user!, { credits });
      if (response.status === 303) {
        const redirectUrl = response.headers.get("Location");
        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      }
      const body = await response.json();
      window.location.href = body.url;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const Message = ({ message }: { message: string }) => (
    <section>
      <p>{message}</p>
    </section>
  );

  return message ? (
    <Message message={message} />
  ) : (
    <div>
      <h1>Buy Credits</h1>
      <input
        type="number"
        value={credits}
        onChange={(e) => setCredits(Number(e.target.value))}
      />
      <button onClick={handleBuy}>Buy</button>
    </div>
  );
};

export default RefillCredits;
