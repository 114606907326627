import { getAnalytics, logEvent } from "firebase/analytics";
import app from "../utils/firebase";

const analytics = getAnalytics(app);

export const useAnalytics = () => {
  const logCustomEvent = (eventName: string, eventParams?: { [key: string]: any }) => {
    logEvent(analytics, eventName, eventParams);
  };

  return { logCustomEvent };
};
