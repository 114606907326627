import { INPUT_CHAR_LIMIT } from "@/constants";

export const validateInput = (input: string) => {
  if (input.length === 0) {
    return {
      valid: false,
      message: "Please double check your input. It seems to be empty. Please try again.",
    };
  }
  if (input.length > INPUT_CHAR_LIMIT) {
    return {
      valid: false,
      message: `Please double check your input. It seems too long. The limit is ${INPUT_CHAR_LIMIT} characters. Please try again.`,
    };
  }
  return { valid: true, message: "" };
};

export const handleQuizGenerationError = (error: Error): string => {
  const errJson = JSON.parse(error.message);
  const errMsg = errJson.error;
  if (errMsg === "Generation limit reached") {
    return "Sorry, you have reached the generation limit for this billing cycle. Please try again later.";
  }
  return errMsg;
};
