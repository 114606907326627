import { SubscriptionData, UserDoc } from "@common/models";
import { User } from "firebase/auth";
import {
  DocumentData,
  doc,
  getDoc,
  getDocFromCache,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";
import config from "./config-loader";
import app from "./firebase";

export const db = initializeFirestore(app, {
  localCache: persistentLocalCache(
    /*settings*/ { tabManager: persistentMultipleTabManager() }
  ),
});

export const mainCollection = config.mainCollectionName;

const getDocByPath = async (
  path: string,
  useCache: boolean = false
): Promise<DocumentData | null> => {
  const document = useCache
    ? await getDocFromCache(doc(db, path))
    : await getDoc(doc(db, path));
  if (document.exists()) {
    return document.data();
  } else {
    return null;
  }
};

export const getUserSubscription = async (
  user: User
): Promise<SubscriptionData | null> => {
  const userDocPath = `${mainCollection}/${user.uid}`;
  return (await getDocByPath(userDocPath)) as SubscriptionData;
};

export async function getGenerationUsage(user: User) {
  const currentYearMonth = new Date().toISOString().slice(0, 7);
  const path = `${mainCollection}/${user.uid}/generation-usage/${currentYearMonth}`;
  return await getDocByPath(path);
}

export const getUserDoc = async (userId: string): Promise<UserDoc | null> => {
  const userDoc = await getDocByPath(`${mainCollection}/${userId}`);
  return userDoc as UserDoc;
};
