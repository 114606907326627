function getTimeUntil(date: Date): string {
  const now = new Date();
  const diffInSeconds = Math.abs(date.getTime() - now.getTime()) / 1000;

  const secondsInHour = 60 * 60;
  const secondsInDay = 60 * 60 * 24;
  const secondsInMonth = secondsInDay * 30; // Approximation
  const secondsInYear = secondsInDay * 365;

    
  if (diffInSeconds < secondsInHour) {
    return "<1h";
  } else if (diffInSeconds < secondsInDay) {
    const diffInHours = diffInSeconds / secondsInHour;
    return `${diffInHours.toFixed(1)}h`;
  } else if (diffInSeconds < secondsInMonth) {
    const diffInDays = diffInSeconds / secondsInDay;
    return `${diffInDays.toFixed(1)}d`;
  } else if (diffInSeconds < secondsInYear) {
    const diffInMonths = diffInSeconds / secondsInMonth;
    return `${diffInMonths.toFixed(1)}mo`;
  } else {
    const diffInYears = diffInSeconds / secondsInYear;
    return `${diffInYears.toFixed(1)}y`;
  }
}
export { getTimeUntil };
