import React, { useState, useRef, useEffect } from "react";

interface EditableFieldProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  className?: string;
}

export const EditableField: React.FC<EditableFieldProps> = ({
  label,
  value,
  onChange,
  onBlur,
  className = "",
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      setIsEditing(false);
      onBlur();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [value]);

  const displayClasses = `cursor-pointer p-3 rounded-md transition-colors ${
    label === "Question"
      ? "bg-indigo-50 text-indigo-900 hover:bg-indigo-100"
      : "bg-gray-50 text-gray-700 hover:bg-gray-100"
  } ${className}`;

  const editClasses =
    "w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400 " +
    className;

  return isEditing ? (
    <textarea
      value={value}
      ref={inputRef}
      onChange={(e) => onChange(e.target.value)}
      className={editClasses}
      onBlur={() => {
        setIsEditing(false);
        onBlur();
      }}
      rows={3}
    />
  ) : (
    <div onClick={() => setIsEditing(true)} className={displayClasses}>
      <div className="font-medium mb-1">{label}</div>
      <pre className="whitespace-pre-wrap overflow-auto">{value}</pre>
    </div>
  );
};
