import { ReviewResult } from "@/pages/ReviewSessionPage";
import { Quiz } from "@common/models";

interface ReviewScheduler {
  getNextReviewTime: (quiz: Quiz, result: ReviewResult) => Date;
}

class SimpleReviewScheduler implements ReviewScheduler {
  getNextReviewTime(quiz: Quiz, result: ReviewResult): Date {
    if (result.result === "again") {
      return new Date(new Date().getTime() + 1000 * 60 * 1); // 1 minute
    } else if (result.result === "hard") {
      return new Date(new Date().getTime() + 1000 * 60 * 80); // 80 minutes
    } else if (result.result === "good") {
      return new Date(new Date().getTime() + 1000 * 60 * 60 * 25); // 25 hours
    }
    return new Date(new Date().getTime() + 1000 * 60 * 60 * 100); // 100 hours
  }
}

class RegularReviewScheduler implements ReviewScheduler {
  getNextReviewTime(quiz: Quiz, result: ReviewResult): Date {
    const modifiers: { [key in ReviewResult["result"]]: number } = {
      easy: 1.5,
      good: 1.0,
      hard: 0.5,
      again: 0.0,
    };
    const timesReviewed = (quiz.currentReviewCount || 0) + 1; // plus 1 because we are reviewing it now

    if (timesReviewed === 0) {
      return this.addHours(new Date(), 1); // First review after 1 hour
    }

    const currentIntervalHours = this.getCurrentIntervalHours(quiz);
    let newIntervalHours = Math.max(0.5, currentIntervalHours * modifiers[result.result]);
    if (result.result === "again") {
      newIntervalHours = 0;
    }
    const nextReviewTime = this.addHours(new Date(), newIntervalHours);
    return nextReviewTime;
  }

  private getCurrentIntervalHours(quiz: Quiz): number {
    if (!quiz.lastReviewedTime || (quiz.currentReviewCount ?? 0) === 0) {
      return 1; // Default to 1 hour if no last review time
    }
    const lastReviewTime = new Date(quiz.lastReviewedTime).getTime();
    const now = new Date().getTime();
    const intervalMilliseconds = now - lastReviewTime;
    return intervalMilliseconds / (1000 * 60 * 60); // Convert milliseconds to hours
  }

  private addHours(date: Date, hours: number): Date {
    return new Date(date.getTime() + hours * 60 * 60 * 1000);
  }
}

export { SimpleReviewScheduler, RegularReviewScheduler };
