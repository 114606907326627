import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import "firebaseui/dist/firebaseui.css";

import DefaultLayout from "./components/DefaultLayout";
import Auth from "./Auth";
import Navbar from "./components/NavBar";
import NotFound from "./pages/404";
import ContactPage from "./pages/ContactPage";
import CollectionPage from "./pages/CollectionPage";
import MainPage from "./pages/MainPage";
import PaymentCancelledPage from "./pages/PaymentCancelledPage";
import PaymentSuccessPage from "./pages/PaymentSuccessPage";
import PricingPage from "./pages/PricingPage";
import Profile from "./pages/ProfilePage";
import RefillCredits from "./pages/RefillCreditsPage";
import FeaturesPage from "./pages/FeaturesPage";
import ReviewSessionPage from "./pages/ReviewSessionPage";
import { CreateQuizPage } from "./pages/CreateQuizPage";

import { AuthProvider } from "./utils/AuthContext";
import { NotificationProvider } from "./utils/NotificationContext";
import config from "./utils/config-loader";
import app from "./utils/firebase";

const MainBlock: React.FC = () => {
  return (
    <main className="flex-grow pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/signin" element={<Auth />} />
          <Route path="refill" element={<RefillCredits />} />
          <Route path="profile" element={<Profile />} />
          <Route path="collections" element={<CollectionPage />} />
          <Route path="create" element={<CreateQuizPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="pricing" element={<PricingPage />} />
          <Route path="features" element={<FeaturesPage />} />
          <Route path="payment-success" element={<PaymentSuccessPage />} />
          <Route path="payment-cancelled" element={<PaymentCancelledPage />} />
          <Route path="review" element={<ReviewSessionPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </main>
  );
};

function App() {
  console.log("env:", config.env);
  console.log("ver:", process.env.REACT_APP_VER);
  const analytics = getAnalytics(app);

  const Footer = () => (
    <footer className="mt-auto bg-white bg-opacity-90">
      <div className="border-t border-indigo-200 w-full"></div>
      <div className="flex justify-end items-center p-4 text-indigo-600">
        <Link
          to="https://cobrakai.notion.site/Privacy-Policy-de420f74e27d4bad843a2ccb07daf1b1"
          className="mx-4 hover:text-indigo-800">
          Privacy Policy
        </Link>
        <Link
          to="https://cobrakai.notion.site/Terms-and-Conditions-for-SmartForge-cb96b9f6f6d240a18644f4775aa7a027"
          className="mx-4 hover:text-indigo-800">
          Terms of Service
        </Link>
        <Link to="/contact" className="mx-4 hover:text-indigo-800">
          Contact Support
        </Link>
      </div>
    </footer>
  );

  return (
    <NotificationProvider>
      <AuthProvider>
        <Router>
          <DefaultLayout>
            <div className="flex flex-col min-h-screen bg-gradient-to-b from-indigo-50 via-white to-teal-50 bg-fixed">
              <Navbar />
              <MainBlock />
              <Footer />
            </div>
          </DefaultLayout>
        </Router>
      </AuthProvider>
    </NotificationProvider>
  );
}

export default App;
