import React, { useEffect, useState } from "react";
import { Button } from "./button";

interface ButtonWithCountdownProps {
  buttonText: string;
  countdownSeconds: number;
  onClick: () => void;
  variant?:
    | "default"
    | "destructive"
    | "outline"
    | "secondary"
    | "ghost"
    | "link"
    | null
    | undefined;
}

const ButtonWithCountdown: React.FC<ButtonWithCountdownProps> = ({
  variant = "default",
  buttonText,
  countdownSeconds,
  onClick,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [countdown, setCountdown] = useState(countdownSeconds);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isDisabled) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            clearInterval(interval!);
            setIsDisabled(false);
            return countdownSeconds;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isDisabled, countdownSeconds]);

  const handleClick = () => {
    onClick();
    setIsDisabled(true);
  };

  return (
    <Button variant={variant} disabled={isDisabled} onClick={handleClick}>
      {isDisabled ? `You can retry in ${countdown}s` : buttonText}
    </Button>
  );
};

export default ButtonWithCountdown;
