const devConfig = {
  env: "dev",
  creditsCollectionName: "credits-dev",
  usersCollectionName: "users-dev",
  subscriptionCollectionName: "subscriptions-dev",
  generationCountCollectionName: "generation-count-dev",
  mainCollectionName: "overall-dev",
  backendUrl: "http://localhost:8080",
};

const devFunctionConfig = {
  env: "dev_func",
  creditsCollectionName: "credits-dev",
  usersCollectionName: "users-dev",
  subscriptionCollectionName: "subscriptions-dev",
  generationCountCollectionName: "generation-count-dev",
  mainCollectionName: "overall-dev",
  backendUrl: "http://127.0.0.1:5001/quiz-creator-cobrakai/us-central1/backend",
};

const stagingConfig = {
  env: "staging",
  creditsCollectionName: "credits-prod",
  usersCollectionName: "users-prod",
  subscriptionCollectionName: "subscriptions-prod",
  generationCountCollectionName: "generation-count-prod",
  mainCollectionName: "overall-dev",
  backendUrl: "https://backend-lqaxcsshpa-uc.a.run.app",
};

const prodConfig = {
  env: "production",
  creditsCollectionName: "credits-prod",
  usersCollectionName: "users-prod",
  subscriptionCollectionName: "subscriptions-prod",
  generationCountCollectionName: "generation-count-prod",
  mainCollectionName: "overall-prod",
  backendUrl: "https://backend-rmb63graka-uc.a.run.app",
};

const config =
  process.env.REACT_APP_ENV === "dev"
    ? devConfig
    : process.env.REACT_APP_ENV === "dev_func"
    ? devFunctionConfig
    : process.env.REACT_APP_ENV === "staging"
    ? stagingConfig
    : prodConfig;

export default config;
