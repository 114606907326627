import {
    Alert,
    AlertColor,
    Snackbar
} from "@mui/material";
import React, { FC, createContext, useContext, useState } from "react";

type NotificationContextType = {
  notification: any;
  setNotification: (notification: any) => void;
  setSeverity: (severity: AlertColor) => void;
};
export const NotificationContext = createContext<NotificationContextType>({
  notification: null,
  setNotification: () => {},
  setSeverity: () => {},
});

export const NotificationProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notification, setNotification] = useState<string | null>();
  const [severity, setSeverity] = useState<AlertColor>("info");

  return (
    <NotificationContext.Provider
      value={{
        notification,
        setNotification,
        setSeverity,
      }}>
      {
        <>
          {notification && (
            <Snackbar
              open={notification != null}
              autoHideDuration={null}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}>
              <Alert
                onClose={() => setNotification(null)}
                severity={severity}
                variant="filled"
                sx={{ width: "100%", fontSize: "1rem" }}>
                {notification}
              </Alert>
            </Snackbar>
          )}
        </>
      }
      {children}
    </NotificationContext.Provider>
  );
};

export function useNotification() {
  return useContext(NotificationContext);
}
