import { User } from "firebase/auth";
import localForage from "localforage";

export class QuizCache {
  private static instance: LocalForage | null = null;

  private constructor() {}

  public static getInstance(user: User): LocalForage {
    if (!QuizCache.instance) {
      QuizCache.instance = localForage.createInstance({
        name: "smartforge",
        storeName: `quizzes-${user.uid}`,
      });
    }
    return QuizCache.instance;
  }
}
