import { Avatar, Badge, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { User, getAuth, signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { hasValidSubscription } from "../services/subscription-service";
import { premiumColor } from "./PremiumButton";
import { useNavigate } from "react-router-dom";
import app from "../utils/firebase";
import { getLogger } from "../utils/logger";
interface AwesomeAvatarProps {
  user: User;
}

export default function AwesomeAvatar({ user }: AwesomeAvatarProps) {
  const [isSubscriptionValid, setIsSubscriptionValid] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const auth = getAuth(app);

  useEffect(() => {
    const checkSubscription = async () => {
      const isSubscriptionValid = await hasValidSubscription(user);
      setIsSubscriptionValid(isSubscriptionValid);
    };

    checkSubscription();
  }, [user]);

  const handleSignOut = async () => {
    setAnchorEl(null);
    try {
      await signOut(auth);
      navigate("/signin");
    } catch (error) {
      getLogger("AwesomeAvatar").error("Error signing out:", error);
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGoto = (url: string) => {
    setAnchorEl(null);
    navigate(url);
  };

  const proBadge = (children: any) => {
    return (
      <Badge
        overlap="circular"
        badgeContent={
          <Button
            variant="contained"
            style={{
              background: premiumColor,
              borderRadius: 3,
              border: 0,
              color: "white",
              height: 14,
              minWidth: "0",
              padding: "0 5px",
              boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
              fontSize: "0.5rem",
            }}>
            Pro
          </Button>
        }
        invisible={!isSubscriptionValid}>
        {children}
      </Badge>
    );
  };

  const getInitials = (name: string) => {
    const parts = name.split(" ");
    let initials = "";
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== "") {
        initials += parts[i][0];
      }
    }
    return initials;
  };

  return (
    <>
      {proBadge(
        <IconButton color="inherit" onClick={handleClick}>
          <Avatar src={user.photoURL ? user.photoURL : undefined}>
            {!user.photoURL && getInitials(user.displayName || "L K")}
          </Avatar>
        </IconButton>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => handleGoto("/profile")}>Profile</MenuItem>
        <MenuItem onClick={() => handleGoto("/collections")}>Collections</MenuItem>{" "}
        <MenuItem onClick={handleSignOut}>Logout</MenuItem>
        <MenuItem onClick={() => handleGoto("/contact")}>Contact Us</MenuItem>
      </Menu>
    </>
  );
}
