import { QuizCache } from "./QuizCache";
import { Quiz } from "@common/models";
import { User } from "firebase/auth";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  deleteDoc,
  where,
  DocumentData,
} from "firebase/firestore";
import callApi from "./backend-caller";
import { db, mainCollection } from "./firestore-db";
import { getLogger } from "./logger";

function getQuizCollectionPath(user: User) {
  return `${mainCollection}/${user.uid}/quiz`;
}

async function getQuizzesFromCache(user: User): Promise<Quiz[]> {
  const quizCache = QuizCache.getInstance(user);
  const quizzes: Quiz[] = [];
  await quizCache.iterate((value, key, iterationNumber) => {
    quizzes.push(value as Quiz);
  });
  return quizzes;
}

async function getQuizzesFromServer(user: User, creationTimeFromExclusive: number) {
  const quizCollectionRef = collection(db, getQuizCollectionPath(user));
  const q = query(
    quizCollectionRef,
    where("creationTime", ">", new Date(creationTimeFromExclusive).toISOString())
  );
  const querySnapshot = await getDocs(q);
  const docs: DocumentData[] = querySnapshot.docs.map((doc) => doc.data());
  return docs as Quiz[];
}

async function getQuizzesWithCache(user: User): Promise<Quiz[]> {
  const quizzesFromCache = await getQuizzesFromCache(user);
  const creationTimeFromExclusive = getLastestCreationTime(quizzesFromCache);
  const quizzesFromServer = await getQuizzesFromServer(user, creationTimeFromExclusive);

  const quizCache = QuizCache.getInstance(user);
  quizzesFromServer.forEach(async (quiz) => {
    await quizCache.setItem(quiz.id, quiz);
  });
  getLogger("quiz-dao").info(
    `Fetched ${quizzesFromCache.length} from cache, ${quizzesFromServer.length} from server`
  );
  return quizzesFromCache.concat(quizzesFromServer);
}

function getLastestCreationTime(quizzes: Quiz[]): number {
  if (quizzes.length === 0) {
    return 0;
  }
  const creationTimes: number[] = quizzes.map((quiz) =>
    new Date(quiz.creationTime ?? 0).getTime()
  );
  const latestCreationTime = Math.max(...creationTimes);
  return latestCreationTime;
}

async function updateQuiz(user: User, quiz: Quiz) {
  const quizCache = QuizCache.getInstance(user);
  await quizCache.setItem(quiz.id, quiz);
  try {
    const res = await callApi("/quizzes", "POST", user, { quiz: quiz });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
  } catch (error) {
    getLogger("quiz-dao").error("An error occurred while updating the quiz:", error);
  }
}

async function upsertQuizWithCache(user: User, quiz: Quiz) {
  const quizCache = QuizCache.getInstance(user);
  await quizCache.setItem(quiz.id, quiz);
  try {
    const quizCollectionPath = getQuizCollectionPath(user);
    const quizDocRef = doc(db, `${quizCollectionPath}/${quiz.id}`);
    await setDoc(quizDocRef, quiz);
  } catch (error) {
    getLogger("quiz-dao").error("An error occurred while saving the quiz:", error);
  }
}

async function deleteQuizWithCache(user: User, quizId: string) {
  const quizCache = QuizCache.getInstance(user);
  await quizCache.removeItem(quizId);
  try {
    const quizCollectionPath = getQuizCollectionPath(user);
    const quizDocRef = doc(db, `${quizCollectionPath}/${quizId}`);
    await deleteDoc(quizDocRef);
  } catch (error) {
    getLogger("quiz-dao").error("An error occurred while deleting the quiz:", error);
  }
}

async function clearCache(user: User) {
  const quizCache = QuizCache.getInstance(user);
  await quizCache.clear();
}

export { getQuizzesWithCache, updateQuiz, upsertQuizWithCache, deleteQuizWithCache, clearCache };
