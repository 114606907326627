import React from "react";
import { Avatar, Card, CardContent, CardHeader } from "@mui/material";
import { User } from "firebase/auth";
import PremiumButton from "../components/PremiumButton";
import { Button } from "../components/ui/button";
import { StripeSubscriptionDetail } from "@/hooks/use-subscription-hook";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useEffect, useState } from "react";
import ButtonWithCountdown from "./ui/button-with-countdown";
interface ProfileInfoProps {
  user: User;
  isSubscriptionValid: boolean;
  stripeSubscriptionDetails: StripeSubscriptionDetail | null;
  onVerifyEmail: () => void;
  onUpgrade: () => void;
  onManageBilling: () => void;
  onRevalidateCache: () => void;
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({
  user,
  isSubscriptionValid,
  stripeSubscriptionDetails,
  onVerifyEmail,
  onUpgrade,
  onManageBilling,
  onRevalidateCache,
}) => {
  return (
    <Card className="bg-white shadow-xl rounded-lg overflow-hidden">
      <CardHeader
        avatar={
          <Avatar
            alt={user.displayName || ""}
            src={user.photoURL || ""}
            className="w-24 h-24 text-4xl"
          />
        }
        title={<h2 className="text-3xl font-bold text-gray-800">{user.displayName}</h2>}
        className={
          isSubscriptionValid
            ? "bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 text-white"
            : "bg-gray-300 text-white"
        }
      />
      <CardContent className="p-6">
        <div className="space-y-4">
          <ProfileField label="Email" value={user.email} />
          <EmailVerificationField
            isVerified={user.emailVerified}
            onVerify={onVerifyEmail}
          />
          <ProfileField label="User ID" value={user.uid} />
          <SubscriptionField
            isSubscriptionValid={isSubscriptionValid}
            onUpgrade={onUpgrade}
          />
          {stripeSubscriptionDetails && (
            <ProfileField
              label={
                stripeSubscriptionDetails.hasActive ? "Next renew date" : "Premium ends"
              }
              value={`${new Date(
                stripeSubscriptionDetails.nextRenewDate
              ).toLocaleDateString(undefined, { timeZoneName: "short" })}`}
            />
          )}
          {isSubscriptionValid && (
            <Button variant="outline" className="mt-4 w-full" onClick={onManageBilling}>
              Manage Billing
            </Button>
          )}
          <Button variant="outline" className="mt-4 w-full" onClick={onRevalidateCache}>
            Revalidate Cache
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

const ProfileField: React.FC<{ label: string; value: string | null | undefined }> = ({
  label,
  value,
}) => (
  <div className="flex items-center">
    <span className="text-sm font-semibold mr-2">{label}:</span>
    <span className="text-gray-700">{value}</span>
  </div>
);

const EmailVerificationField: React.FC<{
  isVerified: boolean;
  onVerify: () => void;
}> = ({ isVerified, onVerify }) => (
  <div className="flex items-center">
    <span className="text-sm font-semibold mr-2">Email Verified:</span>
    {isVerified ? (
      <CheckCircleOutlineIcon className="text-green-500" />
    ) : (
      <div className="flex items-center">
        <CancelOutlinedIcon className="text-red-500 mr-2" />
        <ButtonWithCountdown
          variant="outline"
          buttonText="Send Verify Email"
          countdownSeconds={60}
          onClick={onVerify}
        />
      </div>
    )}
  </div>
);

const SubscriptionField: React.FC<{
  isSubscriptionValid: boolean;
  onUpgrade: () => void;
}> = ({ isSubscriptionValid, onUpgrade }) => (
  <div className="flex items-center">
    <span className="text-sm font-semibold mr-2">Plan:</span>
    {isSubscriptionValid ? (
      <PremiumButton text="PRO" />
    ) : (
      <div className="flex space-x-2 items-center">
        <Button variant="outline" className="h-8 text-xs">
          Free
        </Button>
        <PremiumButton text="upgrade" onClick={onUpgrade} />
      </div>
    )}
  </div>
);

export default ProfileInfo;
