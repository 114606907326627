// quiz-creator-frontend/src/hooks/useSubscription.ts
import { useState, useEffect } from "react";
import {
  getStripeSubscriptionDetails,
  hasValidSubscription,
} from "../services/subscription-service";
import { User } from "firebase/auth";
import { getLogger } from "../utils/logger";
export interface StripeSubscriptionDetail {
  nextRenewDate: number;
  hasActive: boolean;
}
export function useSubscription(user: User | null, isAuthLoading: boolean) {
  const [stripeSubscriptionDetails, setStripeSubscriptionDetails] =
    useState<StripeSubscriptionDetail>();
  const [isSubscriptionValid, setIsSubscriptionValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isAuthLoading && user) {
      (async () => {
        try {
          const stripeDetails = await getStripeSubscriptionDetails(user);
          if (!("error" in stripeDetails)) {
            setStripeSubscriptionDetails(stripeDetails);
          }
          const subscriptionValid = await hasValidSubscription(user);
          setIsSubscriptionValid(subscriptionValid);
        } catch (error) {
          getLogger("useSubscription").error("Error fetching subscription data:", error);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [user, isAuthLoading]);

  return { stripeSubscriptionDetails, isSubscriptionValid, isLoading };
}
