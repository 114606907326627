import { handleUpgrade } from "@/services/subscription-service";
import { User } from "@firebase/auth";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { FC } from "react";

interface PremiumUpgradeProps {
  user: User | null;
  displayText: string;
  onClose: () => void;
}

const premiumFeatureData = [
  {
    icon: "📊",
    text: "More generation limit",
    color: "bg-blue-100 text-blue-600",
  },
  {
    icon: "🕒",
    text: "Save and review results",
    color: "bg-red-100 text-red-600",
  },
  {
    icon: "🧠",
    text: "Better algorithms",
    color: "bg-green-100 text-green-600",
  },
  {
    icon: "💰",
    text: "Only $6/month",
    color: "bg-yellow-100 text-yellow-600",
  },
];

const PremiumUpgrade: FC<PremiumUpgradeProps> = ({ user, displayText, onClose }) => {
  return (
    <div className="bg-gradient-to-br from-indigo-50 to-purple-50 rounded-2xl shadow-lg overflow-hidden">
      <div className="bg-gradient-to-r from-indigo-600 to-purple-600 p-6 text-white">
        <h2 className="text-2xl font-bold text-center flex items-center justify-center">
          Upgrade to{" "}
          <span className="bg-white text-indigo-600 px-3 py-1 rounded-full text-sm font-extrabold uppercase tracking-wide ml-2 inline-flex items-center">
            Pro
          </span>
        </h2>
      </div>
      <div className="p-6">
        <p className="text-gray-700 text-center mb-8 font-medium">{displayText}</p>
        <div className="space-y-6">
          {premiumFeatureData.map((feature, index) => (
            <div
              key={index}
              className="flex items-center space-x-4 bg-white p-3 rounded-lg shadow-sm">
              <div className={`p-2 rounded-full ${feature.color}`}>
                <span className="text-2xl">{feature.icon}</span>
              </div>
              <span className="text-gray-800 font-medium flex-grow">{feature.text}</span>
              <CheckCircleIcon className="h-6 w-6 text-green-500" />
            </div>
          ))}
        </div>
        <div className="mt-10 space-y-4">
          <button
            onClick={() => handleUpgrade(user)}
            className="w-full bg-indigo-600 text-white px-8 py-3 rounded-full font-bold text-lg hover:bg-indigo-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg">
            Upgrade Now
          </button>
          <button
            onClick={onClose}
            className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-3 px-4 rounded-full transition duration-300 ease-in-out">
            Maybe Later
          </button>
        </div>
      </div>
    </div>
  );
};

export default PremiumUpgrade;
