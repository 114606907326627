import ProfileInfo from "@/components/ProfileInfo";
import { useSubscription } from "@/hooks/use-subscription-hook";
import { clearCache } from "@/utils/quiz-dao";
import { Alert, Snackbar, SnackbarCloseReason } from "@mui/material";
import { sendEmailVerification } from "firebase/auth";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/loading/Loading";
import { handleManageBilling, handleUpgrade } from "../services/subscription-service";
import { useAuth } from "../utils/AuthContext";
import { useNotification } from "../utils/NotificationContext";
import { Helmet } from "react-helmet";
import { getLogger } from "../utils/logger";
const Profile: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { user, isLoading: isAuthLoading } = useAuth();
  const navigate = useNavigate();
  const { setNotification, setSeverity } = useNotification();
  const {
    stripeSubscriptionDetails = null,
    isSubscriptionValid,
    isLoading: isSubscriptionLoading,
  } = useSubscription(user, isAuthLoading);

  const handleVerifyEmail = async () => {
    try {
      await sendEmailVerification(user!);
      setOpen(true);
    } catch (error) {
      getLogger("ProfilePage").error("Error sending email verification:", error);
    }
  };

  const handleClose = (_event: any, reason?: SnackbarCloseReason) =>
    reason !== "clickaway" && setOpen(false);

  const onManageBilling = async () => {
    try {
      await handleManageBilling(user!);
    } catch (error) {
      setSeverity("error");
      setNotification((error as Error).message);
    }
  };

  const handleRevalidateCache = async () => {
    await clearCache(user!);
    setSeverity("info");
    setNotification("Cache successfully cleared.");
  };

  if (isSubscriptionLoading) {
    return (
      <div className="min-h-screen flex justify-center">
        <Loading size="large" />
      </div>
    );
  }
  if (!user) {
    navigate("/signin");
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <div className="py-12 px-4 sm:px-6 lg:px-8 flex justify-center bg-gradient-to-b from-indigo-50 to-white">
        <div className="max-w-3xl w-full">
          <ProfileInfo
            user={user}
            isSubscriptionValid={isSubscriptionValid}
            stripeSubscriptionDetails={stripeSubscriptionDetails}
            onVerifyEmail={handleVerifyEmail}
            onUpgrade={() => handleUpgrade(user)}
            onManageBilling={onManageBilling}
            onRevalidateCache={handleRevalidateCache}
          />
        </div>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert
            onClose={handleClose}
            severity="success"
            className="w-full bg-green-100 border-green-400 text-green-700">
            Verification email sent. Please check your email.
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default Profile;
